import { DateTime } from 'luxon'
import { Bill, ExportType } from '@/interfaces/bill'
import { BillFilter } from '@/interfaces/filters'

export const convertDate = (
    dateStartRaw: string | undefined,
    dateEndRaw: string | undefined
) => {
    let minStartDate = null
    let maxStartDate = null
    if (!dateStartRaw) {
        return { minStartDate, maxStartDate }
    }
    minStartDate = DateTime.fromISO(dateStartRaw).toISO()
    if (dateEndRaw) {
        maxStartDate = DateTime.fromISO(dateEndRaw).plus({ days: 1 }).toISO()
    }
    return { minStartDate, maxStartDate }
}

const state = () => ({
    bills: [],
    billsCount: null as number | null
})

const getters = {
    getBills(state: any) {
        return state.bills
    },
    getBillsCount(state: any) {
        return state.billsCount
    }
}

const mutations = {
    setBills(state: any, bills: Bill[]) {
        state.bills = bills
    },
    setBillsCount(state: any, count: number) {
        state.billsCount = count
    }
}

const actions = {
    fetchBills(
        vuexContext: any,
        payload: {
            idOrga: string | undefined
            idBU: string | undefined
            filters: BillFilter
            getOnlyId?: boolean
        }
    ) {
        const { keyword, dateRange, state, pages, orderColumn, orderType } =
            payload.filters
        const { index, perPage } = pages

        const { idOrga, idBU, getOnlyId } = payload
        const offset = (index - 1) * perPage || (0 as number)
        const limit = perPage || (20 as number)
        let params = {
            offset,
            limit,
            keyword,
            min_start_date: undefined as string | undefined | null,
            max_start_date: undefined as string | undefined | null,
            state,
            order_by: 'date,desc'
        }

        if (dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                dateRange[0],
                dateRange[1]
            )
            if (minStartDate) {
                // eslint-disable-next-line
                params = { ...params, min_start_date: minStartDate }
            }
            if (maxStartDate) {
                // eslint-disable-next-line
                params = { ...params, max_start_date: maxStartDate }
            }
        }

        if (orderType && orderColumn) {
            params = {
                ...params,
                order_by: `${orderColumn},${orderType}`
            }
        }

        let url = '/cloudapi'
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }
        url += '/invoices'
        return (this as any).$axios
            .$get(url, { params })
            .then((res: any) => {
                const result = (this as any).$toCamel(res)
                if (!getOnlyId) {
                    vuexContext.commit('setBills', result.invoices)
                    vuexContext.commit('setBillsCount', result.count)
                }

                return result
            })
            .catch((e: any) => {
                throw e
            })
    },
    fetchBillExport(
        _: any,
        payload: {
            idOrga: string | undefined
            idBU: string | undefined
            invoiceNo: string | undefined
            type: ExportType | undefined
        }
    ) {
        const { idOrga, idBU, invoiceNo, type } = payload

        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/invoices/${invoiceNo}/export?file_type=${type}`
        const call = (this as any).$axios
            .get(url)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
        return call
    },
    fetchBatchBillExport(
        _: any,
        payload: {
            idOrga: string | undefined
            idBU: string | undefined
            invoicesNo: string[] | undefined
            type: ExportType | undefined
        }
    ) {
        const { idOrga, idBU, invoicesNo, type } = payload
        let url = '/cloudapi'
        if (idOrga) {
            url += `/organizations/${idOrga}`
        }
        if (idBU) {
            url += `/business-units/${idBU}`
        }

        return (this as any).$axios
            .get(`${url}/invoices/batch-export`, {
                params: {
                    invoices_no: invoicesNo,
                    file_type: type
                }
            })
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}
