import { defineStore } from 'pinia'
import { Contract, ContractDTO, Feature, Plan } from '~/interfaces/flaggy'
import { CancelContractType } from '~/interfaces/organization'
import { keysToCamel, toSnake } from '~/plugins/json/utils'

const useFlaggyStore = defineStore('flaggy', {
    state: () => ({
        adminBlocked: false,
        package: '',
        idOrga: 0,
        contractId: 0,
        renewableDate: null as string | null,
        renewable: true,
        date: null as string | null,
        dateDeleted: null as string | null,
        prices: {
            passOrder: 0,
            passOrderInternational: 0,
            passLicense: {
                annual: 0,
                prorata: 0
            },
            chargeBoxLicense: {
                annual: 0,
                prorata: 0
            },
            chargeBoxRebillingLicense: {
                annual: 0,
                prorata: 0
            },
            chargeBoxDomesticLicense: {
                annual: 0,
                prorata: 0
            },
            simLicense: {
                annual: 0,
                prorata: 0
            },
            overstayParkingFeesLicense: {
                annual: 0,
                prorata: 0
            }
        },
        features: {
            multipleBus: {
                adminReadonly: false,
                activated: false
            },
            blockingPass: {
                adminReadonly: false,
                activated: false
            },
            callForFunds: {
                adminReadonly: false,
                activated: false
            },
            callForFundsTab: {
                adminReadonly: false,
                activated: false
            },
            bankTransfer: {
                adminReadonly: false,
                activated: false
            },
            creditCard: {
                adminReadonly: false,
                activated: false
            },
            costAllocation: {
                adminReadonly: false,
                activated: false
            },
            debit: {
                adminReadonly: false,
                activated: false
            },
            export: {
                adminReadonly: false,
                activated: false
            },
            import: {
                adminReadonly: false,
                activated: false
            },
            billOtherBus: {
                adminReadonly: false,
                activated: false
            },
            passesQuotaReachedAlert: {
                adminReadonly: false,
                activated: false
            },
            orderNumber: {
                adminReadonly: false,
                activated: false
            },
            canEditPrices: {
                adminReadonly: false,
                activated: false
            },
            multipleUsers: {
                adminReadonly: false,
                activated: false
            },
            orders: {
                adminReadonly: false,
                activated: false
            },
            passActivation: {
                adminReadonly: false,
                activated: false
            },
            externalApiConnection: {
                adminReadonly: false,
                activated: false
            },
            overstayParkingFees: {
                adminReadonly: false,
                activated: false
            }
        } as Record<string, Feature>,
        options: {
            callForFunds: false,
            billOtherBus: false
        } as Record<string, boolean>
    }),
    getters: {
        getPackage(state) {
            return state.package
        },
        getIdOrga(state) {
            return state.idOrga
        },
        getContractRenewalDate(state) {
            return state.renewableDate || ''
        },
        getContractDate(state) {
            return state.date || ''
        },
        getContractPrices(state) {
            return {
                passOrder: state.prices.passOrder / 100,
                passOrderInternational:
                    state.prices.passOrderInternational / 100,
                passLicense: {
                    annual: state.prices.passLicense.annual / 100,
                    prorata: state.prices.passLicense.prorata / 100
                },
                chargeBoxLicense: {
                    annual: state.prices.chargeBoxLicense.annual / 100,
                    prorata: state.prices.chargeBoxLicense.prorata / 100
                },
                chargeBoxRebillingLicense: {
                    annual: state.prices.chargeBoxRebillingLicense.annual / 100,
                    prorata:
                        state.prices.chargeBoxRebillingLicense.prorata / 100
                },
                chargeBoxDomesticLicense: {
                    annual: state.prices.chargeBoxDomesticLicense.annual / 100,
                    prorata: state.prices.chargeBoxDomesticLicense.prorata / 100
                },
                simLicense: {
                    annual: state.prices.simLicense.annual / 100,
                    prorata: state.prices.simLicense.prorata / 100
                },
                overstayParkingFeesLicense: {
                    annual:
                        state.prices.overstayParkingFeesLicense.annual / 100,
                    prorata:
                        state.prices.overstayParkingFeesLicense.prorata / 100
                }
            }
        },
        hasFeatureActivated(state) {
            return (feature: string) =>
                state.features[feature]?.activated || false
        },
        hasFeatureReadOnly(state) {
            return (feature: string) =>
                state.features[feature]?.adminReadonly || false
        },
        hasOptionActivated(state) {
            return (configuration: string) =>
                state.options[configuration] || false
        },
        hasContract(state) {
            return !!state.date
        },
        isAdminBlocked(state) {
            return state.adminBlocked
        },
        isContractRenewable(state) {
            return state.renewable
        }
    },
    actions: {
        setContract(contractInfo: Contract) {
            this.idOrga = contractInfo.organizationId
            this.contractId = contractInfo.id
            this.package = contractInfo.package
            this.features = contractInfo.features
            this.options = contractInfo.options
            this.prices = contractInfo.prices
            this.date = contractInfo.date
            this.renewableDate = contractInfo.renewableDate
            this.renewable = contractInfo.renewable
            this.dateDeleted = contractInfo.dateDeleted
            this.adminBlocked = contractInfo.adminBlocked
        },
        fetchContract(context: any, idOrga: number) {
            if (!idOrga) return
            const urlAPI = `/flaggyapi/organizations/${idOrga}/contract`

            this.package = Plan.LEGACY
            return context.$axios
                .$get(urlAPI)
                .then((contractDTO: ContractDTO) => {
                    const contractInfo = keysToCamel(contractDTO)
                    this.setContract(contractInfo as Contract)
                })
                .catch((e: any) => {
                    throw e
                })
        },
        updateFeature(
            context: any,
            payload: {
                idOrga: number
                feature: string
                value: boolean
            }
        ) {
            const { idOrga, feature, value } = payload
            const url = `/flaggyapi/organizations/${idOrga}/features/${toSnake(
                feature
            )}`
            return context.$axios
                .$patch(url, { activated: value })
                .then((contractDTO: ContractDTO) => {
                    const contractInfo = keysToCamel(contractDTO)
                    this.setContract(contractInfo as Contract)
                })
                .catch((e: any) => {
                    throw e
                })
        },
        updateOption(
            context: any,
            payload: {
                idOrga: number
                option: string
                value: boolean
            }
        ) {
            const { idOrga, option, value } = payload
            const url = `/flaggyapi/organizations/${idOrga}/options/${toSnake(
                option
            )}`
            return context.$axios
                .$patch(url, { activated: value })
                .then((contractDTO: ContractDTO) => {
                    const contractInfo = keysToCamel(contractDTO)
                    this.setContract(contractInfo as Contract)
                })
                .catch((e: any) => {
                    throw e
                })
        },
        updateContract(
            context: any,
            payload: {
                idOrga: string
                property: string
                value: number | string
            }
        ) {
            const { idOrga, property, value } = payload
            const url = `/flaggyapi/organizations/${idOrga}/contract/${this.contractId}`
            let params = {
                pass_order_price: this.prices.passOrder,
                pass_order_international_price:
                    this.prices.passOrderInternational,
                pass_license_price: this.prices.passLicense.annual,
                charge_box_license_price: this.prices.chargeBoxLicense.annual,
                sim_license_price: this.prices.simLicense.annual,
                charge_box_rebilling_license_price:
                    this.prices.chargeBoxRebillingLicense.annual,
                charge_box_domestic_license_price:
                    this.prices.chargeBoxDomesticLicense.annual,
                date: this.date,
                renewable: this.renewable,
                renewable_date: this.renewableDate
            }

            params = {
                ...params,
                [property]: value,
                renewable: property === 'date' ? true : this.renewable
            }
            return context.$axios
                .$patch(url, params)
                .then((contractDTO: ContractDTO) => {
                    const contractInfo = keysToCamel(contractDTO)
                    this.setContract(contractInfo as Contract)
                })
                .catch((e: any) => {
                    throw e
                })
        },
        cancelContract(
            context: any,
            idOrga: string,
            cancelContractType: CancelContractType
        ) {
            const url = `/flaggyapi/organizations/${idOrga}/contract/${this.contractId}`
            const params = {
                pass_order_price: this.prices.passOrder,
                pass_order_international_price:
                    this.prices.passOrderInternational,
                pass_license_price: this.prices.passLicense.annual,
                charge_box_license_price: this.prices.chargeBoxLicense.annual,
                sim_license_price: this.prices.simLicense.annual,
                charge_box_rebilling_license_price:
                    this.prices.chargeBoxRebillingLicense.annual,
                charge_box_domestic_license_price:
                    this.prices.chargeBoxDomesticLicense.annual,
                date:
                    cancelContractType === CancelContractType.IMMEDIATE
                        ? null
                        : this.date,
                renewable: false
            }

            return context.$axios
                .$patch(url, params)
                .then((contractDTO: ContractDTO) => {
                    const contractInfo = keysToCamel(contractDTO)
                    this.setContract(contractInfo as Contract)
                })
                .catch((e: any) => {
                    throw e
                })
        },
        blockContract(context: any, idOrga: number, block: boolean) {
            return context.$axios
                .$patch(`/flaggyapi/organizations/${idOrga}/temporary-block`, {
                    block
                })
                .then((contractDTO: ContractDTO) => {
                    const contractInfo = keysToCamel(contractDTO)
                    this.setContract(contractInfo as Contract)
                })
                .catch((e: any) => {
                    throw e
                })
        }
    }
})

export default useFlaggyStore
