import { TypeZone, Zone, ZoneJSON } from '~/interfaces/zone'
import { axiosListWrapper, axiosWrapper } from '~/utilitary/storeWrapperVuex'

const actions = {
    createUpdateZone(
        this: any,
        _: any,
        payload: { zone: Zone; idBU: number; idZone: number | undefined }
    ) {
        const { zone, idBU, idZone } = payload
        const newZoneFormated: ZoneJSON = this.$toSnake(zone)
        let method = 'post'
        let url = `/supervisionapi/business-units/${idBU}/zones`
        if (idZone) {
            method = 'patch'
            url += `/${idZone}`
        }
        return axiosWrapper.bind(this)(method, url, newZoneFormated)
    },
    fetchZones(
        this: any,
        _: any,
        payload: {
            search: string
            idBU: number
            type: TypeZone | null
            idDeliveryPoint: number | null
        }
    ) {
        const { search, idDeliveryPoint, idBU, type } = payload
        const params = {
            keyword: search || undefined,
            type,
            delivery_point_id: idDeliveryPoint as number | string | undefined
        }
        if (idDeliveryPoint === null) {
            params.delivery_point_id = 'null'
        }
        const url = `/supervisionapi/business-units/${idBU}/zones`
        return axiosWrapper.bind(this)('get', url, { params })
    },
    fetchZone(this: any, _: any, payload: { idBU: number; idZone: number }) {
        const { idBU, idZone } = payload
        const url = `/supervisionapi/business-units/${idBU}/zones/${idZone}`
        return axiosWrapper.bind(this)('get', url)
    },
    fetchDetailedZones(
        _: any,
        payload: {
            idBU: number
            index: number
            zoneName: string
            zoneType: TypeZone
            isConnected?: boolean
            rebillingActivated?: boolean
            keyword?: string
            overstayParkingTariffActivated?: boolean
            hasOverstayParkingLicense?: boolean
            overstayParkingTariffRenewable?: boolean
        }
    ) {
        const {
            index,
            idBU,
            zoneName,
            zoneType,
            rebillingActivated,
            isConnected,
            overstayParkingTariffActivated,
            hasOverstayParkingLicense,
            overstayParkingTariffRenewable,
            keyword
        } = payload

        const params = {
            type: zoneType,
            zone_name: zoneName,
            rebilling_activated: rebillingActivated,
            is_connected: isConnected,
            overstay_parking_tariff_activated: overstayParkingTariffActivated,
            has_overstay_parking_license: hasOverstayParkingLicense,
            overstay_parking_tariff_renewable: overstayParkingTariffRenewable
        }

        const url = `/supervisionapi/business-units/${idBU}/detailed_zones`
        return axiosListWrapper
            .bind(this)(url, params, {
                pages: { perPage: 20, index },
                searchField: keyword
            })
            .then((res: any) => ({
                count: res.totalCount,
                zones: res.items
            }))
    },
    getCollaboratorZones(
        _: any,
        payload: {
            idBU: number
            index: number
            perPage: number
            collaboratorId: number
        }
    ) {
        const { idBU, collaboratorId, index, perPage } = payload
        const params = {
            type: 'DOMESTIC',
            collaborator_id: collaboratorId
        }
        const url = `/supervisionapi/business-units/${idBU}/detailed_zones`
        return axiosListWrapper
            .bind(this)(url, params, {
                pages: { perPage, index },
                searchField: ''
            })
            .then((res: any) => ({
                count: res.totalCount,
                zones: res.items
            }))
    },
    assignDeliveryPointToZone(
        this: any,
        _: any,
        payload: {
            idDeliveryPoint: number | null
            idBU: number
            idZone: number
        }
    ) {
        const { idBU, idZone, idDeliveryPoint } = payload
        const url = `/supervisionapi/business-units/${idBU}/zones/${idZone}`
        return axiosWrapper.bind(this)('patch', url, {
            delivery_point_id: idDeliveryPoint
        })
    },
    delete(
        this: any,
        _: any,
        payload: {
            idBU: number
            idZone: number
        }
    ) {
        const { idBU, idZone } = payload
        const url = `/supervisionapi/business-units/${idBU}/zones/${idZone}`
        return axiosWrapper.bind(this)('delete', url)
    },
    fetchAddressListJAWG(this: any, _: any, location: string) {
        const url = 'jawgapi/places/v1/autocomplete'
        return axiosWrapper
            .bind(this)('get', url, {
                params: {
                    text: location,
                    'access-token': this.$config.JAWG_API_KEY,
                    size: 5,
                    sources: 'oa'
                }
            })
            .then((geoJsonResponse: any) => {
                if (!geoJsonResponse?.features) return []
                return geoJsonResponse.features.map((suggestion: any) => ({
                    address: suggestion.properties?.name || '',
                    label: `${suggestion.properties?.name || ''}, ${
                        suggestion.properties?.postalcode || ''
                    } ${suggestion.properties?.locality || ''}`,
                    lat: suggestion.geometry.coordinates[1],
                    lng: suggestion.geometry.coordinates[0],
                    city: suggestion.properties?.locality || '',
                    postalCode: suggestion.properties?.postalcode || '',
                    countryCode: suggestion.properties?.countryCode || ''
                }))
            })
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    actions
}
