import {
    Pass,
    PassOwner,
    RuleHolidays,
    RuleWeekly,
    TransferType
} from '@/interfaces/pass'
import { FilterPass, FilterTeslaPass } from '@/interfaces/filters'
import validHexa from '@/validations/hexa'
import { axiosListWrapper, axiosWrapper } from '~/utilitary/storeWrapperVuex'
import { Address, DTOAddress } from '~/interfaces/order'

const actions = {
    fetchPasses(
        _: any,
        payload: {
            idOrga: number
            idBU: number
            filters: FilterPass
            collaboratorAssigned: number
            vehicleAssigned: number
        }
    ) {
        const { idOrga, idBU, filters, collaboratorAssigned, vehicleAssigned } =
            payload
        const {
            pages,
            hasLimit,
            isAssigned,
            searchField,
            lost,
            type,
            isActivated
        } = filters
        const offset = (pages.index - 1) * pages.perPage || (0 as number)
        const limit = pages.perPage || (20 as number)
        const keyword = searchField || (null as null | string)

        const params = {
            offset,
            limit,
            keyword,
            lost,
            is_activated: isActivated,
            has_limit: hasLimit,
            is_assigned: isAssigned,
            type,
            collaborator_assigned: collaboratorAssigned,
            vehicle_assigned: vehicleAssigned
        }

        if (!type) {
            delete params.type
        }

        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes`
            ;(this as any).$axios
                .$get(url, { params })
                .then((res: any) => {
                    const result: {
                        count: number
                        next: null
                        previous: null
                        passes: Pass[]
                    } = (this as any).$toCamel(res)
                    result.passes.forEach((pass: Pass) => {
                        pass.limit = pass.limit ? pass.limit / 100 : null
                    })
                    resolve(result)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    fetchSupervisedPasses(
        _: any,
        payload: {
            idOrga: number
            idBU: number
            filters: FilterPass
        }
    ) {
        const { idOrga, idBU, filters } = payload
        const { pages, hasLimit, isAssigned, searchField, lost, type } = filters
        const offset = (pages.index - 1) * pages.perPage || (0 as number)
        const limit = pages.perPage || (20 as number)
        const keyword = searchField || (null as null | string)

        const params = {
            offset,
            limit,
            keyword,
            lost,
            has_limit: hasLimit,
            is_assigned: isAssigned,
            type
        }

        if (!type) {
            delete params.type
        }

        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes/supervised`
            ;(this as any).$axios
                .$get(url, { params })
                .then((res: any) => {
                    const result: {
                        count: number
                        next: null
                        previous: null
                        passes: Pass[]
                    } = (this as any).$toCamel(res)
                    result.passes.forEach((pass: Pass) => {
                        pass.limit = pass.limit ? pass.limit / 100 : null
                    })
                    resolve(result)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    fetchTeslaPasses(
        this: any,
        _: any,
        payload: { idBU: number; filters: FilterTeslaPass }
    ) {
        const { idBU, filters } = payload
        const { teslaLinkStatus } = filters
        const params = {
            tesla_link_status: teslaLinkStatus
        }
        const url = `/fleetapi/business-units/${idBU}/tesla_associations`
        return axiosListWrapper
            .bind(this)(url, params, filters)
            .then((res: any) => ({
                totalCount: res.totalCount,
                passes: res.items,
                previous: res.previous,
                next: res.next
            }))
    },
    getPass(_: any, payload: { idOrga: number; idBU: number; idPass: number }) {
        const { idOrga, idBU, idPass } = payload
        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes/${idPass}`
            ;(this as any).$axios
                .$get(url)
                .then((res: any) => {
                    const pass: Pass = (this as any).$toCamel(res)
                    pass.limit = pass.limit ? pass.limit / 100 : null
                    resolve(pass)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    fetchLicense(_: any, payload: { idBU: number; idPass: number }) {
        const { idBU, idPass } = payload
        return new Promise((resolve, reject) => {
            const url = `/fleetapi/business-units/${idBU}/passes/${idPass}`
            ;(this as any).$axios
                .$get(url)
                .then((res: any) => {
                    const passLicense: Pass = (this as any).$toCamel(res)
                    resolve(passLicense)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    newPrivatePass(
        this: any,
        _: any,
        payload: {
            idAuthorization: string | undefined
            pass: Pass
            idOrga: number
            idBU: number
        }
    ) {
        const { pass, idBU, idAuthorization } = payload
        const newPass = {
            authorization_id: undefined as number | undefined,
            rfid_uid: undefined as string | undefined,
            number: pass.number,
            comment: pass.comment
        }
        if (idAuthorization) {
            newPass.authorization_id = parseInt(idAuthorization, 10)
        } else {
            newPass.rfid_uid = pass.rfidUid || undefined
        }
        return new Promise((resolve, reject) => {
            if (!newPass.rfid_uid && !newPass.authorization_id) {
                reject(new Error('pass.form.missing.uid'))
            } else if (!newPass.number) {
                reject(new Error('pass.form.missing.number'))
            } else if (
                !newPass.authorization_id &&
                newPass.rfid_uid &&
                !validHexa(newPass.rfid_uid)
            ) {
                reject(new Error('pass.form.invalid.uid'))
            } else {
                this.$axios
                    .$post(
                        `/supervisionapi/business-units/${idBU}/passes`,
                        newPass
                    )
                    .then((res: any) => {
                        resolve(res)
                    })
                    .catch((e: any) => {
                        reject(e)
                    })
            }
        })
    },
    async newClassicPass(
        this: any,
        _: any,
        payload: { pass: Pass; idOrga: number; idBU: number }
    ) {
        const { idBU, pass, idOrga } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes`
        const res = await axiosWrapper.bind(this)('post', url, {
            number: pass.number,
            type: pass.type
        })
        return res
    },
    async verifyPassCommunity(
        this: any,
        _: any,
        payload: { passNumber: String; idOrga: number; idBU: number }
    ) {
        const { passNumber } = payload
        const url = `/cloudapi/passes/${passNumber}`
        const res = await axiosWrapper.bind(this)('get', url)
        return res
    },
    updateInfoPass(
        _: any,
        payload: { pass: Pass; idOrga: number; idBU: number }
    ) {
        const { pass, idOrga, idBU } = payload
        const updatePass = {
            number: pass.number,
            limit: pass.limit ? pass.limit * 100 : null,
            comment: pass.comment,
            vehicle_id: pass.vehicle?.id,
            collaborator_id: pass.collaborator?.id,
            cost_center_id: pass.costCenter?.id
        }

        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$put(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes/${pass.id}`,
                    updatePass
                )
                .then((res: any) => {
                    const passUpdated = (this as any).$toCamel(res)
                    passUpdated.limit = passUpdated.limit
                        ? passUpdated.limit / 100
                        : null
                    resolve(passUpdated)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    orderPass(
        this: any,
        _: any,
        payload: {
            passNumber: number
            licenseNumber: number
            idBU: number
            shippingAddress: Address
            collaborators: number[]
            vehicles: number[]
            isLicenseMode: boolean
        }
    ) {
        const {
            passNumber,
            licenseNumber,
            idBU,
            shippingAddress,
            collaborators,
            vehicles,
            isLicenseMode
        } = payload

        let dtoAddress: DTOAddress | null = null
        if (!isLicenseMode) {
            dtoAddress = {
                firstName: shippingAddress?.firstName || '',
                lastName: shippingAddress?.name || '',
                recipient: shippingAddress?.company || '',
                addressLine1: shippingAddress?.addressLine1 || '',
                addressLine2: shippingAddress?.addressLine2 || '',
                postalCode: shippingAddress?.postalCode || '',
                city: shippingAddress?.city || '',
                country: shippingAddress?.countryName || '',
                countryCode: shippingAddress?.countryCode || ''
            }
        }

        const orderPass = {
            passNumber: passNumber || undefined,
            licenseNumber: licenseNumber || undefined,
            shippingAddress: dtoAddress,
            collaboratorsIds: collaborators || [],
            vehiclesIds: vehicles || []
        }

        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$post(
                    `/fleetapi/business-units/${idBU}/orders`,
                    this.$toSnake(orderPass)
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    fetchOwnerPass(this: any, _: any, serialNumber: string): PassOwner {
        return this.$axios
            .$get(`/fleetapi/passes?serial=${serialNumber}`)
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    transferPass(
        this: any,
        _: any,
        payload: {
            idOrga: number
            idBU: number
            idPass: number
            typeTransfer: TransferType
        }
    ) {
        const { idBU, idPass, typeTransfer } = payload
        const params = {
            business_unit_id: idBU
        }

        let url = `/fleetapi/passes/${idPass}/transfer/`
        url +=
            typeTransfer === 'external'
                ? 'from_community_pass'
                : 'from_business_unit_pass'
        return this.$axios
            .$patch(url, params)
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    actionsOnPass(
        this: any,
        _: any,
        payload: { idBU: number; idPass: number; params: object }
    ) {
        const { idBU, idPass, params } = payload
        return this.$axios
            .$patch(`/fleetapi/business-units/${idBU}/passes/${idPass}`, params)
            .then((res: any) => this.$toCamel(res))
    },
    activate(
        this: any,
        vuexContext: any,
        payload: { idBU: number; idPass: number }
    ) {
        const { idBU, idPass } = payload
        const params = {
            activated: true
        }

        return vuexContext.dispatch('actionsOnPass', {
            idBU,
            idPass,
            params
        })
    },
    deactivate(
        this: any,
        vuexContext: any,
        payload: { idBU: number; idPass: number }
    ) {
        const { idBU, idPass } = payload
        const params = {
            activated: false
        }

        return vuexContext.dispatch('actionsOnPass', {
            idBU,
            idPass,
            params
        })
    },
    deletePass(
        this: any,
        _: any,
        payload: { idBU: number; idOrga: number; idPass: number }
    ) {
        const { idBU, idPass, idOrga } = payload
        return this.$axios
            .$delete(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/passes/${idPass}`
            )
            .then((res: any) => this.$toCamel(res))
    },
    cancelLicense(
        this: any,
        vuexContext: any,
        payload: { idBU: number; idPass: number }
    ) {
        const { idBU, idPass } = payload
        const params = {
            renewable: false
        }

        return vuexContext.dispatch('actionsOnPass', {
            idBU,
            idPass,
            params
        })
    },
    renewLicense(
        this: any,
        vuexContext: any,
        payload: { idBU: number; idPass: number }
    ) {
        const { idBU, idPass } = payload
        const params = {
            renewable: true
        }
        return vuexContext.dispatch('actionsOnPass', {
            idBU,
            idPass,
            params
        })
    },
    isLost(this: any, _: any, payload: { idBU: number; idPass: number }) {
        const { idBU, idPass } = payload
        const params = {
            lost: true
        }
        return this.$axios
            .$post(
                `/fleetapi/business-units/${idBU}/passes/${idPass}/report_lost`,
                params
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    isFound(this: any, _: any, payload: { idBU: number; idPass: number }) {
        const { idBU, idPass } = payload
        const params = {
            lost: false
        }
        return this.$axios
            .$post(
                `/fleetapi/business-units/${idBU}/passes/${idPass}/report_lost`,
                params
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    approveTeslaAssociation(
        this: any,
        _: any,
        payload: { idBU: number; uuid: string }
    ) {
        const { uuid, idBU } = payload
        const params = {
            tesla_link_status: 'CONFIRMED'
        }
        const url = `fleetapi/business-units/${idBU}/tesla_associations/${uuid}`
        return axiosWrapper.bind(this)('patch', url, params)
    },
    rejectTeslaAssociation(
        this: any,
        _: any,
        payload: { idBU: number; uuid: string }
    ) {
        const { uuid, idBU } = payload
        const url = `fleetapi/business-units/${idBU}/tesla_associations/${uuid}`
        return axiosWrapper.bind(this)('delete', url)
    },
    unlinkTeslaAssociation(
        this: any,
        _: any,
        payload: { idBU: number; uuid: string }
    ) {
        const { uuid, idBU } = payload
        const url = `fleetapi/business-units/${idBU}/tesla_associations/${uuid}`
        return axiosWrapper.bind(this)('delete', url)
    },
    fetchRuleWeekly(
        this: any,
        _: any,
        payload: { idBU: number; idPass: number | null }
    ) {
        const { idBU, idPass } = payload
        let url = `blockyapi/business-units/${idBU}/rule/weekly`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/weekly`
        }

        return axiosWrapper.bind(this)('get', url)
    },
    saveRuleWeekly(
        this: any,
        _: any,
        payload: {
            idBU: number
            idPass: number | null
            ruleWeekly: RuleWeekly
            overrideCustomRules: boolean
        }
    ) {
        const { idBU, idPass, ruleWeekly, overrideCustomRules } = payload
        let url = `blockyapi/business-units/${idBU}/rule/weekly`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/weekly`
        }
        const params = {
            business_unit_rule: ruleWeekly.businessUnitRule,
            override_custom_rules: overrideCustomRules,
            days: {
                SUNDAY: (this as any).$toSnake(ruleWeekly.days.SUNDAY),
                MONDAY: (this as any).$toSnake(ruleWeekly.days.MONDAY),
                TUESDAY: (this as any).$toSnake(ruleWeekly.days.TUESDAY),
                WEDNESDAY: (this as any).$toSnake(ruleWeekly.days.WEDNESDAY),
                THURSDAY: (this as any).$toSnake(ruleWeekly.days.THURSDAY),
                FRIDAY: (this as any).$toSnake(ruleWeekly.days.FRIDAY),
                SATURDAY: (this as any).$toSnake(ruleWeekly.days.SATURDAY)
            }
        }
        return axiosWrapper.bind(this)('put', url, params)
    },
    deleteRuleWeekly(
        this: any,
        _: any,
        payload: { idBU: number; idPass: number }
    ) {
        const { idBU, idPass } = payload
        const url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/weekly`

        return axiosWrapper.bind(this)('delete', url)
    },
    fetchRuleHolidays(
        this: any,
        _: any,
        payload: { idBU: number; idPass: number | null }
    ) {
        const { idBU, idPass } = payload
        let url = `blockyapi/business-units/${idBU}/rule/holidays`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/holidays`
        }

        return axiosWrapper.bind(this)('get', url)
    },
    saveRuleHolidays(
        this: any,
        _: any,
        payload: {
            idBU: number
            idPass: number | null
            ruleHolidays: RuleHolidays
            onlyBU: boolean
            activatePasses: boolean
        }
    ) {
        const { idBU, idPass, ruleHolidays, activatePasses, onlyBU } = payload
        let url = `blockyapi/business-units/${idBU}/rule/holidays`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/holidays`
        }
        let typeAPI = 'post'
        if (ruleHolidays.id) {
            url += `/${ruleHolidays.id}`
            typeAPI = onlyBU ? 'patch' : 'put'
        }
        const params = {
            ...(this as any).$toSnake(ruleHolidays),
            activate_passes: activatePasses
        }
        return axiosWrapper.bind(this)(typeAPI, url, params)
    },
    deleteRuleHolidays(
        this: any,
        _: any,
        payload: { idBU: number; idPass: number; idRule: number }
    ) {
        const { idBU, idPass, idRule } = payload
        let url = `blockyapi/business-units/${idBU}/rule/holidays/${idRule}`
        if (idPass) {
            url = `blockyapi/business-units/${idBU}/passes/${idPass}/rule/holidays/${idRule}`
        }

        return axiosWrapper.bind(this)('delete', url)
    }
}

export default {
    namespaced: true as true,
    actions
}
