import { BusinessUnit } from './callForFunds'
import { Connector, ConnectorJSON } from './connector'
import { DeliveryPoint } from './deliveryPoint'
import { LicenseType } from './license'
import { TypeZone } from './zone'

export type SpecificPayload = {
    type?: 'HARD' | 'SOFT'
    connector_id?: number
}
export enum CommandType {
    RESET = 'RESET',
    UNLOCK = 'UNLOCK_CONNECTOR',
    TRIGGER = 'TRIGGER_MESSAGE',
    REMOTE_START_TRANSACTION = 'REMOTE_START_TRANSACTION',
    REMOTE_STOP_TRANSACTION = 'REMOTE_STOP_TRANSACTION',
    CHANGE_CONFIGURATION = 'CHANGE_CONFIGURATION',
    UPDATE_FIRMWARE = 'UPDATE_FIRMWARE'
}

export enum evseStatuses {
    AVAILABLE = 'AVAILABLE',
    PREPARING = 'PREPARING',
    CHARGING = 'CHARGING',
    SUSPENDED_EVSE = 'SUSPENDED_EVSE',
    SUSPENDED_EV = 'SUSPENDED_EV',
    FINISHING = 'FINISHING',
    RESERVED = 'RESERVED',
    UNAVAILABLE = 'UNAVAILABLE',
    FAULTED = 'FAULTED',
    UNKNOWN = 'UNKNOWN'
}
export interface ChargeboxUpdate {
    id: number
    isConnected: boolean
    lastCall: string
}

export enum ChargeBoxStatus {
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
    REBOOT_REQUIRED = 'REBOOT_REQUIRED'
}

export interface PassInput {
    id: number
    text: string
    number: string
    style: string | null
    classes: string | null
}
export enum PassAccess {
    DENY_ALL = 'DENY_ALL',
    FULL_ACCESS = 'FULL_ACCESS',
    FULL_CHARGEMAP_PASS = 'FULL_CHARGEMAP_PASS',
    FULL_BU_ACCESS = 'FULL_BU_ACCESS',
    FULL_ORGANIZATION_ACCESS = 'FULL_ORGANIZATION_ACCESS',
    LOCAL_PASSES = 'LOCAL_PASSES'
}

export enum ConnectorCurrentTypes {
    AC_SINGLE = 'AC_SINGLE',
    AC_TRI = 'AC_TRI',
    DC = 'DC'
}

export enum connectorTypes {
    TYPEA = 'DOMESTIC_TYPE_A',
    TYPEB = 'DOMESTIC_TYPE_B',
    TYPEC = 'DOMESTIC_TYPE_C',
    TYPED = 'DOMESTIC_TYPE_D',
    TYPEF = 'DOMESTIC_TYPE_F',
    TYPEG = 'DOMESTIC_TYPE_G',
    TYPEH = 'DOMESTIC_TYPE_H',
    TYPEI = 'DOMESTIC_TYPE_I',
    TYPEJ = 'DOMESTIC_TYPE_J',
    TYPEK = 'DOMESTIC_TYPE_K',
    TYPEL = 'DOMESTIC_TYPE_L',
    TYPEM = 'DOMESTIC_TYPE_M',
    TYPE2 = 'MENNEKES_TYPE_2',
    TYPE2CABLEATTACHED = 'MENNEKES_TYPE_2_CABLE_ATTACHED',
    TYPE3A = 'TYPE_3A',
    TYPE3C = 'TYPE_3C',
    TYPE1 = 'TYPE_1',
    CEEBLUE = 'CEE_BLUE',
    CEERED = 'CEE_RED',
    TYPE4 = 'CHADEMO',
    NEMA520 = 'NEMA_5_20',
    AVCON = 'AVCON',
    LARGEPADDLEINDUCTIVE = 'LARGE_PADDLE_INDUCTIVE',
    SMALLPADDLEINDUCTIVE = 'SMALL_PADDLE_INDUCTIVE',
    COMBOTYPE2 = 'COMBO_TYPE_2',
    COMBOTYPE1 = 'COMBO_TYPE_1',
    CHINAPART2 = 'CHINA_GB_PART_2',
    CHINAPART3 = 'CHINA_GB_PART_3',
    MARECHAL = 'MARECHAL',
    IEC309DC = 'IEC_309_DC',
    T23 = 'T23',
    T15 = 'T15',
    T25 = 'T25',
    TESLATYPE2 = 'TESLA_TYPE_2',
    TESLASPECIFIC = 'TESLA_ROADSTER',
    TESLASUPERCHARGEREURO = 'TESLA_SUPERCHARGER_EU',
    TESLACOMBOCCS = 'Tesla Combo CCS'
}
export interface Evse {
    id: number | null
    licenseId: number | null
    status?: evseStatuses
    chargeboxLicenceType?: LicenseType
    connectors: Connector[]
    simLicenseId?: number
}

export enum OverstayParkingTariffCharge {
    DURING_CHARGE = 'DURING_CHARGE',
    POST_CHARGE = 'POST_CHARGE'
}

export interface OverstayParkingTariff {
    activated: boolean
    businessUnitId: number
    deliveryPointId: number
    type: OverstayParkingTariffCharge
    price: number
    periodStart: string | null
    periodEnd: string | null
    leniencyDuration: number
    dateCreated: string
}

export interface Chargebox {
    chargeboxSerialNumber: string
    chargepointSerialNumber: string
    businessUnit: BusinessUnit
    firmwareVersion: string
    lastCall: string | null
    iccid: {} | null
    id: number
    activated: boolean
    licensesRenewable: boolean
    licensesRenewalDate: string | null
    identity: string
    displayIdentity: string | null
    imsi: {} | null
    catalogModelSerialNumber: string | null
    ip: string
    isConnected: boolean
    meterSerialNumber: string | null
    meterType: string | null
    licenseType?: LicenseType
    model: string
    name: string | null
    vendor: string | null
    zone?: {
        id: number | null
        businessUnitId: number
        name?: string
        address?: string
        postalCode?: string
        city?: string
        type?: TypeZone | null
        countryCode?: string
        deliveryPoint?: DeliveryPoint
    } | null
    evses?: Evse[] | null
    currentType: ConnectorCurrentTypes
    dateActivated: string | null
    overstayParkingFeesActivationDate: string | null
    overstayParkingTariff: OverstayParkingTariff | null
    overstayParkingFeesLicenseRenewable: boolean | null
}

export interface ChargeboxJSON {
    chargebox_serial_number: string
    chargepoint_serial_number: string
    firmware_version: string
    business_unit: BusinessUnit
    last_call: string | null
    iccid: {} | null
    id: number
    identity: string
    imsi: {} | null
    ip: string
    is_connected: boolean
    activated: boolean
    licenses_renewable: boolean
    licenses_renewal_date: string | null
    meter_serial_number: string | null
    meter_type: string | null
    model: string
    name: string | null
    vendor: string | null
    zone: {
        id: number
        business_unit_id: number
        name?: string
        address?: string
        postal_code?: string
        city?: string
        country_code?: string
    } | null
    evses?:
        | {
              id: number | null
              licenseId: number | null
              status?: evseStatuses
              connectors: ConnectorJSON[]
          }[]
        | null
}
